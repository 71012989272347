import { TitleDivider } from 'components/TitleDivider'
import { VideoPlayer } from 'components/VideoPlayer'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section, { SECTION_VARIANTS } from 'components/Section'
import React from 'react'
import { Typography } from 'components/Contentful'
import { StatGrid } from 'components/StatGrid'

function AboutUsVideo({ content }) {
  const { eyebrow, title, subtitle, disclaimer, videoPlayer, grid, config } =
    useMemoizedContentGetter(content, [
      'eyebrow',
      'title',
      'subtitle',
      'disclaimer',
      'videoPlayer',
      'grid',
      'config',
    ])

  const variantBg = config?.jsonContent?.sectionVariant ?? SECTION_VARIANTS.bgPrimaryHighlightBlue

  return (
    <Section
      className={'overflow-hidden px-0 py-12 lg:px-0 lg:py-16'}
      variant={variantBg}
      data-testid="AboutUsVideo_section"
    >
      <TitleDivider
        eyebrowBlack={variantBg !== SECTION_VARIANTS.bgBlue100}
        title={title}
        eyebrow={eyebrow}
        subtitle={grid ? null : subtitle}
        classNameTitleText="headingMd lg:displayLg font-medium"
        classNameSubtitleText="bodySm lg:displaySm font-light"
      />
      <VideoPlayer content={videoPlayer} aspectRatio={16 / 9} />
      {subtitle && (
        <Typography
          data-testid="title-divider-subtitle"
          className={'flex-col max-w-[808px] m-auto pt-12'}
          content={subtitle.textContent}
        />
      )}
      {grid && <StatGrid grid={grid} />}
      {disclaimer && (
        <Typography
          data-testid="title-divider-disclaimer"
          className={'max-w-[808px] text-center text-[#42546b] m-auto pt-12'}
          content={disclaimer.textContent}
        />
      )}
    </Section>
  )
}
export { AboutUsVideo }
export default AboutUsVideo
